<template>
  <TTView>
    <VRow>
      <VCol>
        <h1>Copyright</h1>
      </VCol>
    </VRow>
    <VRow>
      <VCol>
        <h2>{{ $t('create_copyright.sub_header') }}</h2>
      </VCol>
    </VRow>
    <VRow>
      <VCol cols="12">
        <VForm
          ref="form"
          v-model="isValid"
          lazy-validation
        >
          <VRow>
            <VCol>
              <TTTextField
                v-model="copyright.name"
                :label="$t('create_copyright.name')"
                :rules="[rules.required]"
              />
            </VCol>
          </VRow>
          <VRow>
            <VCol>
              <UploadImage
                v-model="copyright.prerollUrl"
                :rules="[rules.videoOrNull]"
                label="Pre-roll"
                outlined
                :accept="'video'"
                class="tt-light-mono-4"
                :disabled-always="false"
              />
            </VCol>
          </VRow>
          <VRow>
            <VCol>
              <UploadImage
                v-model="copyright.postrollUrl"
                :rules="[rules.videoOrNull]"
                label="Post-roll"
                outlined
                :accept="'video'"
                class="tt-light-mono-4"
                :disabled-always="false"
              />
            </VCol>
          </VRow>
          <VRow>
            <VCol>
              <UploadImage
                v-model="copyright.watermarkUrl"
                :rules="[rules.imageOrNull]"
                label="Watermark"
                outlined
                :accept="'image'"
                class="tt-light-mono-4"
                :disabled-always="false"
              />
            </VCol>
          </VRow>
          <VRow>
            <VCol>
              <TTBtn
                :disabled="!isValid "
                @click="createCopyright"
              >
                {{ $t('create_copyright.create_btn') }}
              </TTBtn>
            </VCol>
          </VRow>
        </VForm>
      </VCol>
    </VRow>
    <VRow>
      <VCol cols="12">
        <TTDataTable
          :headers="headers"
          :items="copyrightItems"
          :loading="contentItems.loading"
          disable-pagination
          disable-sort
        >
          <template #item.id="{ item }">
            <span class="word-break-all">
              {{ item.id }}
            </span>
          </template>
          <template #item.pre-roll="{ item }">
            <a
              :href="item.prerollUrl"
              :title="item.prerollUrl"
              class="word-break-all"
            >
              {{ getFileNameFromUrl(item.prerollUrl) }}
            </a>
          </template>

          <template #item.post-roll="{ item }">
            <a
              :href="item.postrollUrl"
              :title="item.postrollUrl"
              class="word-break-all"
            >
              {{ getFileNameFromUrl(item.postrollUrl) }}
            </a>
          </template>

          <template #item.watermark="{ item }">
            <a
              :href="item.watermarkUrl"
              :title="item.watermarkUrl"
              class="word-break-all"
            >
              {{ getFileNameFromUrl(item.watermarkUrl) }}
            </a>
          </template>

          <template #item.actions="{ item }">
            <VTooltip left>
              <template #activator="{ on, attrs }">
                <VBtn
                  icon
                  small
                  color="warning"
                  v-bind="attrs"
                  :to="{ name : Names.R_LEARNING_COURSE_V2_CONTENT_COPYRIGHT_EDIT, params : { copyrightId : item.id} }"
                  v-on="on"
                >
                  <VIcon small>
                    $edit
                  </VIcon>
                </VBtn>
              </template>
              <span>{{ $t('create_copyright.edit') }}</span>
            </VTooltip>
            <VTooltip left>
              <template #activator="{ on, attrs }">
                <VBtn
                  icon
                  small
                  color="red"
                  v-bind="attrs"
                  v-on="on"
                  @click="archive(item.id)"
                >
                  <VIcon small>
                    $archive
                  </VIcon>
                </VBtn>
              </template>
              <span>{{ $t('create_copyright.archive') }}</span>
            </VTooltip>
          </template>
        </TTDataTable>
        <VPagination
          v-if="contentItemsPagesTotal > 0"
          v-model="contentItems.page"
          total-visible="10"
          :length="contentItemsPagesTotal"
        />
      </VCol>
    </VRow>
  </TTView>
</template>

<script>

import { required, fileTypeOrNull, getFileNameFromUrl } from '@front.backoffice/common';
import UploadImage from '../../components/UploadImage.vue';

export default {
  name: 'CreateCopyright',
  components: {
    UploadImage,
  },
  inject: ['Names'],
  data() {
    return {
      copyright: {
        name: '',
        prerollUrl: '',
        postrollUrl: '',
        watermarkUrl: '',
      },
      headers: [
        { text: 'Id', value: 'id', width: '10%' },
        { text: 'Название', value: 'name', width: '20%' },
        { text: 'Pre-roll', value: 'pre-roll', width: '20%' },
        { text: 'Post-roll', value: 'post-roll', width: '20%' },
        { text: 'Watermark', value: 'watermark', width: '20%' },
        { text: 'Действия', value: 'actions', width: '10%' },
      ],
      contentItems: {
        page: 1,
        limit: 20,
        total: 0,
        loading: false,
      },
      copyrightItems: [],
      isValid: false,
    };
  },
  computed: {
    contentItemsPagesTotal() {
      return Math.ceil(this.contentItems.total / this.contentItems.limit);
    },
    rules() {
      return {
        required: required(),
        imageOrNull: fileTypeOrNull('image', 'или изображение или пустая строка'),
        videoOrNull: fileTypeOrNull('video', 'или видео или пустая строка'),
      };
    },
  },
  watch: {
    'contentItems.page': {
      async handler(pageNumber) {
        await this.fetch(pageNumber, this.contentItems.limit);
      },
      immediate: true,
    },
  },
  methods: {
    getFileNameFromUrl,
    async createCopyright() {
      try {
        this.contentItems.loading = true;

        const { copyright } = this;
        const data = { copyright };

        await this.$di.api.TtCourse.copyrightCreate({ data });

        this.$di.notify.snackSuccess(this.$t('create_copyright.success'));

        await this.fetch(this.contentItems.page);
        this.cleanCopyright();
      } catch (error) {
        this.$di.notify.errorHandler(error);
      } finally {
        this.contentItems.loading = false;
      }
    },
    async fetch(page = 1, limit = this.contentItems.limit) {
      try {
        this.contentItems.loading = true;

        const data = { page, limit };

        const {
          copyrights = [],
          pagination: { total = 0 },
        } = await this.$di.api.TtCourse.copyrightIndex({ data });

        this.contentItems.total = total;
        this.copyrightItems = copyrights;
      } catch (error) {
        this.$di.notify.errorHandler(error);
      } finally {
        this.contentItems.loading = false;
      }
    },
    cleanCopyright() {
      this.copyright.prerollUrl = '';
      this.copyright.postrollUrl = '';
      this.copyright.watermarkUrl = '';
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },
    async archive(id) {
      try {
        await this.$di.api.TtCourse.copyrightArchive({
          data: {
            id,
          },
        });
        this.$di.notify.snackSuccess(this.$t('create_copyright.archived'));
        await this.fetch(this.contentItems.page, this.contentItems.limit);
      } catch (e) {
        console.error(e);
        this.$di.notify.errorHandler(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.word-break-all {
  word-break: break-all;
}
</style>
